var _stats = null;
var _cmn = null;
var _manager = null;

/**
 * CmnManager
 * @class Page CmnManager
 * @returns
 */
var CmnManager = Klass.create();

CmnManager.extend(JqManager);

//definition start
CmnManager.extend({
/**
 * init
 */
init:function(){
	this.ua = getUA();
	this.webkit = ((this.ua["ua"]=="chrome")&&(this.ua["ua"]=="safari"))?true:false;
	this.html = {};
	this.body = {};
	this.anim_time = 600;
	this.anim_ease = "easeOutCubic";
	this.anim_ease2 = "easeOutQuart";
	this.anim_ease3 = "easeOutBack";
	this.anim_ease_in = "cubic-bezier(.75,.01,.69,.08)";
	this.anim_ease_out = "cubic-bezier(.3,1,.26,1)";
	this.anim_ease_inout = "cubic-bezier(.8,-0.01,.26,1)";
	this.is_down = false;
	this.is_finish = true;
	this.is_first = true;
	this.is_load = false;
	this.fps = 24;
	this.ie8 = false;
	this.modern = false;
	this.raf = null;
	this.timer = null;
	if(this.ua["sp"]){
		$("body").addClass("sp");
	}
},
/**
 * 設定
 */
set:function(){
	this.setParam();
	this.setObject();
	var oCom = new Command();
	this.start_command = oCom;
	oCom.execute();
},
/**
 * 開始
 */
start:function(){
	//console.log("_cmn start");
	var oCom = new Command();
	this.start_command = oCom;
	//oCom.async(this.loader, this.loader.hide, [1],"");
	//oCom.async(this.loader, this.loader.show, [this.anim_time],"");
//	oCom.call(this, this.setRouter, [],"");
	oCom.call(this, this.setState, [],"");
	oCom.call(this, this.setEvent, [],"");
	oCom.async(this, this.startContents, [null],"");
//	oCom.call(this.loader, this.loader.hide, [this.anim_time],"");
	oCom.callback(this,function(){
		_cmn.is_first = false;
		_cmn.start_command = null;
	},[],"");
	oCom.execute();
},
/**
 * setRouter
 */
setRouter:function(){
	$.pjax({
		area : '#main',
		wait: 0,
		ajax: { timeout: 3000},
		load: {
			script: true,
			reload: '[src*="script.min.js"],[src*="tmpl.js"]',
			css: false,
		}
	});
	$('a:not([target])').on("click",_cmn.checkLink);
	$(document).bind('pjax:render', function(e){_cmn.startContents(e);});
	$(document).bind('pjax:fetch', function(e){_cmn.finishContents(e);});
},
/**
 * checkLink
 */
checkLink:function(e){
	//遷移中はキャンセル
	if(_cmn.is_load){ return false; }
	var $target = $(e.currentTarget);
	var strLink = $target.attr("href");
	if(!strLink){ return; }
	var oCom = new Command();
	//oCom.call(_cmn.header,_cmn.header.closeMenu,[],"");
	oCom.sleep(_cmn.anim_time*0.3);
	if($.url(strLink).attr("directory").replace(/\//g,"") !== $.url().attr("directory").replace(/\//g,"")){
//		oCom.async(_cmn,_cmn.finishContents,[],"");
//		oCom.async(_cmn,_cmn.startContents,[],"");
		oCom.call(this,function(){_cmn.is_load = true;},[],"");
		oCom.call(this,function(){$.pjax.click($target);},[],"");
	}
	oCom.execute();
	return false;
},
/**
 * setManager
 * local manager生成
 */
setManager:function(){
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	_manager = null;
	_manager = Manager.create();
	oCom.call(_manager,_manager.set,[],"");
	oCom.async(_manager,_manager.start,[],"");
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * startContents
 */
startContents:function(e){
	//console.log("_cmn startContents");
	//終了処理を待って処理
	if(!_cmn.is_finish){
		setTimeout(function(e){_cmn.startContents(e);},300);
		return;
	}
	//ページ表示
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(_cmn,function(){ _cmn.is_finish = false; },[],"");
	oCom.call(_cmn,_cmn.passiveBody,[],"");
	oCom.call(_cmn,_cmn.presetContents,[],"");
	oCom.async(_cmn,_cmn.setManager,[],"");
	//oCom.call(_cmn.loader, _cmn.loader.hide, [_cmn.anim_time],"");
	oCom.sleep(_cmn.anim_time);
	oCom.call(_cmn,_cmn.activeBody,[],"");
	oCom.async(_cmn,_cmn.showContents,[],"");
	oCom.call(_cmn,function(){_manager.after();},[],"");
	oCom.call(_cmn,function(){_cmn.is_load = false;},[],"");
//	if(!_cmn.is_first && $.url().attr("directory").replace("/","") === ""){
//		//TOP表示(2回目以降)
//		oCom.call(_cmn,_cmn.gotoTop,["#list",0,1],"");
//	}
	if(oAsync&&oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * finishContents
 */
finishContents:function(){
	//console.log("_cmn finishContents");
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(_manager,_manager.finish,[],"");
	oCom.call(_cmn,_cmn.passiveBody,[],"");
	oCom.async(_cmn,_cmn.hideContents,[],"");
	oCom.call(_cmn,function(){ _cmn.is_finish = true; },[],"");
	if(oAsync&&oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * presetContents
 */
presetContents:function(){
	//console.log("_cmn presetContents");
	this.main = null;
	this.main = $("#main");
	this.main.css({opacity:0});
	this.main.removeClass("hide");
},
/**
 * contents表示
 */
showContents:function(){
	//console.log("_cmn showContents");
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this.loader, this.loader.hide, [this.anim_time],"");
	//$("#loader").addClass('active');
	oCom.call(this,function(){ this.main.animate({opacity:1},this.anim_time,this.anim_ease); },[],"");
	oCom.sleep(this.anim_time);
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * contents非表示
 */
hideContents:function(){
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.async(this.loader, this.loader.hide, [1],"");
	oCom.call(this.loader, this.loader.show, [this.anim_time],"");
	oCom.call(this,function(){ this.main.animate({opacity:0},this.anim_time,this.anim_ease); },[],"");
	oCom.sleep(this.anim_time);
	oCom.call(this,function(){
		this.main.css({opacity:0});
		this.main.addClass("hide");
	},[],"");
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
///////////////////////////////////////////////////////////////////////////////////
//setting
///////////////////////////////////////////////////////////////////////////////////
/**
 * パラメータ設定
 */
setParam:function(){
	var iVersion = ~~this.ua["ver"];
	this.modern = true;
	if(this.ua["ua"] === "ie" && iVersion <= 9){
		this.modern = false;
	}
	if(this.ua["sp"]){
		this.event_move = "touchmove";
		this.event_down = "touchstart";
		this.event_up = "touchend";
	}
},
/**
 * オブジェクト設定
 */
setObject:function(){
	//this.loader = $("#loader");
	this.loader = Loader.create($("#loader"));
	//this.header = Header.create($("header"));
	//this.header.set();
	this.contents = $("#contents");
	this.wrapper = $(".wrapper");
	this.main = $("#main");
},
/**
 * 初期状態設定
 */
setState:function(){
	//console.log("_cmn setState");
	if(_cmn.ua["ua"] !== "ie"){
	}else{
	}
	if(_cmn.ua["sp"]){
		$("body").addClass("sp");
	}
	this.resize();
	$(".subset").remove();
},
///////////////////////////////////////////////////////////////////////////////////
//event
///////////////////////////////////////////////////////////////////////////////////
/**
 * イベント設定
 */
setEvent:function(){
	var strEvent = _cmn.ua["sp"] ? "resize orientationchange" : "resize";
	$(window).bind(strEvent,this.resize);
	smartRollover();
},
/**
 * setScroll
 */
setScroll:function(){
	$('a[href^="#"]').on("click",function(e) {
	//console.log('setScroll click');
		var $target = $(e.currentTarget);
		var strHash = $target.attr("href");
		var iPos = $target.attr("data-pos");
			//iPos = iPos ? ~~iPos : 0;
		//console.log(_cmn.ua["sp"]);
		if(_cmn.ua["sp"]){
			iPos = 0;
		} else {
			iPos = iPos ? ~~iPos : 0;
		}
		_cmn.gotoTop(strHash,iPos);
		return false;
	});
},
/**
 * スクロール位置ベースのイベント
 */
setWayPoint:function(){
	//top
	$("#top").waypoint(function(direction){
	},{offset:300});
},
/**
 * resize
 */
resize:function(e){
},
///////////////////////////////////////////////////////////////////////////////////
//function
///////////////////////////////////////////////////////////////////////////////////
track:function(e){
	var $target = $(e.currentTarget);
	var o = {
		type:"ua",
		target:$target.attr("target"),
		href:$target.attr("href"),
		mode:$target.attr("data-mode"),
		category:$target.attr("data-category"),
		action:$target.attr("data-action"),
		label:$target.attr("data-label"),
		val:~~$target.attr("data-val")
	};
	_cmn.postGA(o);
	if(o.target !== "_blank"){
		return false;
	}
},
///////////////////////////////////////////////////////////////////////////////////
//util
///////////////////////////////////////////////////////////////////////////////////
/**
 * preload
 */
preload:function($container){
	var oThis = this;
	var aData = [];
	var oData = {};
	var oAsync = this.getAsyncCommand(arguments);
	var oPreloader = new createjs.LoadQueue(true);
	var manifest = [];
	var oURLBase = $.url();
	var strHostDomain = oURLBase.attr("host");
	var oImg = $container.find("img");
	var oURL = {};
	
	//img
	var iImgLen = oImg.length;
	for(var k = 0; k < iImgLen; k++){
		o = {};
		o.src = oImg[k].src;
		if(!o.src){
			continue;
		}
		
		o.id = oImg[k].src;
		o.type = createjs.PreloadJS.IMAGE;
		oURL = $.url(o.src);
		
		//クロスドメインは除外
		if(oURL.attr("file")&&(oURL.attr("host")===strHostDomain)){
			manifest.push(o);
		}
	}
	
	oPreloader.on("fileload",function(e) {
	},this);
	oPreloader.on("progress",function(e) {
		oThis.per_target = (e.loaded * 100) >> 0;
	},this);
	oPreloader.on("complete", function(e) {
		e.target.onFileLoad = null;
		e.target.onComplete = null;
		e.target.onProgress = null;
		oThis.per_target = 100;
	},this);
	oPreloader.loadManifest(manifest);
	oPreloader.load();
	
	//表示更新処理
	this.progress_timer = setTimeout(function(){
		oThis.updateProgress(oThis,oAsync);
	},50);
},
/**
 * progress
 */
updateProgress:function(oThis,oAsync){
	var iPer = oThis.per_target;
	if(iPer >= 100){
		oAsync.publish();
		oThis.progress_timer = null;
	}else{
		oThis.progress_timer = setTimeout(function(){
			oThis.updateProgress(oThis,oAsync);
		},50);
	}
},
/**
 * gotoTop
 */
gotoTop:function(hash,pos,time){
	//udp top title animation
	var iTime = typeof time !== "number" ? 800 : time;
	var $target = $(hash);
	var iY = 0;
	if((_cmn.ua["sp"] == true)){
		var isHtmlScroll = (function(){
		    var html = $('html'), top = html.scrollTop();
		    var el = $('<div/>').height(10000).prependTo('body');
		    html.scrollTop(10000);
		    var rs = !!html.scrollTop();
		    html.scrollTop(top);
		    el.remove();
		    return rs;
		})();
		
		if($target.attr("id") != undefined){
			iY = $target.offset().top;
		}
		$(isHtmlScroll ? 'html' : 'body').animate({scrollTop: iY + pos},iTime,_cmn.anim_ease);
		return false;
	}else{
		if($target.attr("id") != undefined){
			iY = $target.offset().top;
		}
		$('html,body').animate({scrollTop: iY + pos},iTime,_cmn.anim_ease);
		return false;
	}
},
/**
 * 画面の一番上に移動
 */
setTop:function(){
	scrollTo(0,0);
},
/**
 * 初回オープニング用resize
 */
opening_resize:function(){
},
/**
 * スクロール禁止
 */
passiveBody:function(){
	$("body,html").css({"height":"","overflow":"hidden"});
},
/**
 * 全体表示
 */
activeBody:function(){
	$("body,html").css({"height":"","overflow":""});
},
passiveScrollX:function(){
	$("body,html").css({"overflowX":"hidden"});
},
activeScrollX:function(){
	$("body,html").css({"overflowX":"auto"});
},

	/**
	 * スクロール禁止
	 */
	cancelWindowScroll:function(){
		//console.log('--- cancelWindowScroll');
		if(!_cmn.ua["sp"]){
			//PC用
			_cmn.scroll_event = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
			$(document).on(_cmn.scroll_event,function(e){e.preventDefault();});
		}else{
			//SP用
			_cmn.scroll_event = function( event ){
				event.preventDefault();
			}
			//alert('スクロール禁止');
			window.addEventListener( 'touchmove' , _cmn.scroll_event , { passive: false } );

		}
	},
	/**
	 * スクロール許可
	 */
	setWindowScroll:function(){
		//console.log('--- setWindowScroll');
		if(!_cmn.ua["sp"]){
			//PC用
			var scroll_event = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
			$(document).off(_cmn.scroll_event);
		}else{
			//alert('スクロール許可');
			window.removeEventListener( 'touchmove' , _cmn.scroll_event, { passive: false } );
		}
	},


});
//definition end
